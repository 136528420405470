import { getCampaignAudiance, updateCampaignAudiance } from './../../services/campaignv1';
import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import CampaignSteps from '@/components/campaign/createCampaignSteps';
import { getSegmentList } from '@/services/sessionFilter';
import { getAllStaticSegments } from '@/services/staticSegment';
import CampaignFooter from '@/components/campaign/createCampaignFooter';
import _ from 'lodash';

export default {
  name: 'campaignTargetAudiance',
  props: {},
  components: {
    UserPropertyFilterComponent,
    CampaignSteps,
    CampaignFooter
  },
  data() {
    return {
      step: 1,
      fetchingData: true,

      campaignId: null,
      campaign: null,
      savingCampaign: false,

      hideOldSegmentOptionsInInclude: true,
      hideOldSegmentOptionsInExclude: true,

      include: {
        targetAudiance: {
          audiance_type: 'all',
          segment_id: null,
          static_segment_id: null,
          segment_v2: { segments: [], lists: [] },
          filter: { filters: [], performedFilter: [], notPerformedFilter: [], matchAll: true }
        },
        targetAudianceResult: null,

        targetAudianceStepErrors: []
      },

      excludeCustomers: false,
      exclude: {
        targetAudiance: {
          audiance_type: 'segment',
          segment_id: null,
          static_segment_id: null,
          segment_v2: { segments: [], lists: [] },
          filter: { filters: [], performedFilter: [], notPerformedFilter: [], matchAll: true }
        },
        targetAudianceResult: null,

        targetAudianceStepErrors: []
      },

      hasUnsavedChanges: false,

      segmentList: null,
      staticSegmentList: null
    };
  },
  mixins: [],
  watch: {
    include: {
      deep: true,
      handler() {
        this.hasUnsavedChanges = true;
      }
    },
    exclude: {
      deep: true,
      handler() {
        this.hasUnsavedChanges = true;
      }
    }
  },

  methods: {
    async fetchDetailsForEdit() {
      try {
        this.fetchingData = true;

        // Fetch Details
        let response = await getCampaignAudiance(this.campaignId);

        this.campaign = response.data.campaign;
        if (!this.campaign) {
          this.$router.replace({ name: '404' });
          return;
        }

        if (response.data.audiance) {
          // Include
          if (response.data.audiance.include) {
            let includeAudience = response.data.audiance.include;
            includeAudience.filter = includeAudience.filter ? JSON.parse(includeAudience.filter) : undefined;
            if (includeAudience.segment_v2) {
              includeAudience.segment_v2 = JSON.parse(includeAudience.segment_v2);
            }

            this.include.targetAudiance = this.overrideJson(this.include.targetAudiance, includeAudience);

            // Show old segment options if segment_v2 is not present.
            if (['segment', 'custom', 'static_list'].indexOf(this.include.targetAudiance.audiance_type) >= 0) {
              this.hideOldSegmentOptionsInInclude = false;
            }
          }

          // Exclude
          this.excludeCustomers = false;
          if (response.data.audiance.exclude) {
            this.excludeCustomers = true;
            let excludeAudience = response.data.audiance.exclude;
            excludeAudience.filter = excludeAudience.filter ? JSON.parse(excludeAudience.filter) : undefined;
            if (excludeAudience.segment_v2) {
              excludeAudience.segment_v2 = JSON.parse(excludeAudience.segment_v2);
            }

            this.exclude.targetAudiance = this.overrideJson(this.exclude.targetAudiance, excludeAudience);

            // Show old segment options if segment_v2 is not present.
            if (['segment', 'custom', 'static_list'].indexOf(this.exclude.targetAudiance.audiance_type) >= 0) {
              this.hideOldSegmentOptionsInExclude = false;
            }
          }
        }

        this.fetchingData = false;
        requestAnimationFrame(() => {
          this.hasUnsavedChanges = false;
        });

        this.fetchSegmentList();
        this.fetchStaticSegmentList();
      } catch (err) {
        this.reportError(err);
        this.errorToast('Failed to read campaign audiance. Please contact support.');
        this.fetchingData = false;
      }
    },

    async fetchSegmentList() {
      if (this.segmentList != null) return;
      try {
        let result = await getSegmentList(true);
        let list = [];
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].is_deleted) {
            if (result.data[i].id == this.include.targetAudiance.segment_id) {
              list.push(result.data[i]);
            }
          } else {
            list.push(result.data[i]);
          }
        }
        this.segmentList = list;
        console.log('segment list result is', this.segmentList);
      } catch (err) {
        this.reportError(err);
        this.errorToast(`Failed to fetch segments. Please contact support.`);
      }
    },

    async fetchStaticSegmentList() {
      if (this.staticSegmentList != null) return;
      try {
        let result = await getAllStaticSegments(false);
        this.staticSegmentList = result.data;

        // Check if deleted segment exists.
        let found = _.find(this.staticSegmentList, (sg) => {
          return sg.id == this.include.targetAudiance.static_segment_id;
        });
        if (!found) this.include.targetAudiance.static_segment_id = null;
      } catch (err) {
        this.reportError(err);
        this.errorToast(`Failed to fetch static lists. Something went wrong.`);
      }
    },

    async validateAndSave() {
      try {
        // Handle Exclude.
        let includeValidationResult = this.validateIncludeExcludeObj(this.include, 'include');
        if (!includeValidationResult.valid) return false;
        let includeAudience = includeValidationResult.audienceInfo;

        let excludeAudience = null;
        if (this.excludeCustomers) {
          let excludeValidationResult = this.validateIncludeExcludeObj(this.exclude, 'exclude');
          if (!excludeValidationResult.valid) return false;
          excludeAudience = excludeValidationResult.audienceInfo;
        }

        this.savingCampaign = true;

        let params = {
          include: includeAudience,
          exclude: excludeAudience
        };
        // console.log('Api Payload:', params);

        // Save Changes
        await updateCampaignAudiance(this.campaignId, params);

        this.savingCampaign = false;

        // Since, Called from parent.
        return true;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Failed to save changes. Please contact support.');
        this.savingCampaign = false;

        // Since, Called from parent.
        return false;
      }
    },

    validateIncludeExcludeObj(audienceTypeObj, type) {
      // Handle Include.
      audienceTypeObj.targetAudienceResult = JSON.parse(JSON.stringify(audienceTypeObj.targetAudiance));
      audienceTypeObj.targetAudianceStepErrors = [];

      // Read filters
      if (audienceTypeObj.targetAudienceResult.audiance_type == 'custom') {
        let segmentCriteria = this.$refs[type + 'UserFilter'].getFilters();

        if (!segmentCriteria) {
          audienceTypeObj.targetAudianceStepErrors.push('Please specify criteria.');
        } else {
          audienceTypeObj.targetAudienceResult.filter = JSON.stringify(segmentCriteria);
          audienceTypeObj.targetAudienceResult.segment_id = null;
          audienceTypeObj.targetAudienceResult.segment_v2 = null;
        }
      } else if (audienceTypeObj.targetAudienceResult.audiance_type == 'segment') {
        if (!audienceTypeObj.targetAudienceResult.segment_id) {
          audienceTypeObj.targetAudianceStepErrors.push('Please select segment.');
        } else {
          audienceTypeObj.targetAudienceResult.filter = null;
          audienceTypeObj.targetAudienceResult.static_segment_id = null;
          audienceTypeObj.targetAudienceResult.segment_v2 = null;
        }
      } else if (audienceTypeObj.targetAudienceResult.audiance_type == 'segment-v2') {
        if (audienceTypeObj.targetAudienceResult.segment_v2.segments.length == 0 && audienceTypeObj.targetAudienceResult.segment_v2.lists.length == 0) {
          audienceTypeObj.targetAudianceStepErrors.push('Please select segment or static list.');
        } else {
          audienceTypeObj.targetAudienceResult.segment_v2 = JSON.stringify(audienceTypeObj.targetAudienceResult.segment_v2);
          audienceTypeObj.targetAudienceResult.filter = null;
          audienceTypeObj.targetAudienceResult.static_segment_id = null;
          audienceTypeObj.targetAudienceResult.segment_id = null;
        }
      } else if (audienceTypeObj.targetAudienceResult.audiance_type == 'static_list') {
        if (!audienceTypeObj.targetAudienceResult.static_segment_id) {
          audienceTypeObj.targetAudianceStepErrors.push('Please select static list.');
        } else {
          audienceTypeObj.targetAudienceResult.filter = null;
          audienceTypeObj.targetAudienceResult.segment_id = null;
          audienceTypeObj.targetAudienceResult.segment_v2 = null;
        }
      } else {
        // All Customers option.
        audienceTypeObj.targetAudienceResult.filter = null;
        audienceTypeObj.targetAudienceResult.segment_id = null;
        audienceTypeObj.targetAudienceResult.static_segment_id = null;
        audienceTypeObj.targetAudienceResult.segment_v2 = null;
      }
      // If errors found, return;
      if (audienceTypeObj.targetAudianceStepErrors.length > 0) {
        return false;
      }

      return {
        valid: true,
        audienceInfo: audienceTypeObj.targetAudienceResult
      };
    },

    async onSaveDraft() {
      let isSaved = await this.validateAndSave();
      if (isSaved) {
        this.successToast('Draft Saved.');
      }
    },

    async onGoBack() {
      // Check for changes first.
      if (this.hasUnsavedChanges) {
        try {
          let result = await this.$confirm('You have unsaved changes, save and proceed?', 'Save Changes?', {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Save Changes',
            cancelButtonText: 'Ignore Changes'
          });

          // Save changes
          result = await this.validateAndSave();
          if (!result) return;
        } catch (err) {
          // Igore changes
        }
      }

      // Go To Previous
      this.$router.push({ name: 'campaignGeneralInfoUpdate', params: { id: this.campaignId } });
    },

    async onGoNext() {
      let result = await this.validateAndSave();
      if (result) {
        // Redirect to targetAudiancePage
        this.savingCampaign = true;
        this.$router.push(`/campaign/update/${this.campaign.channel}/${this.campaignId}`);
      }
    }
  },

  mounted() {
    this.campaignId = this.$route.params.id;
    if (this.campaignId == null) {
      // Redirect to 404
      this.$router.replace('/404');
      return;
    }
    this.fetchDetailsForEdit();
  }
};
