<template>
  <div id="campaignTargetAudiance">
    <div class="loadingDiv" v-if="fetchingData" v-loading="true" style="min-height: 300px"></div>

    <template v-else>
      <!-- Campaign Steps -->
      <CampaignSteps v-if="campaign" :step="step" :campaign="campaign"></CampaignSteps>

      <!-- Include Customers -->
      <template>
        <el-card class="audianceInfo">
          <div slot="header">
            <span class="headerText">Which all customer you want to send message to?</span>
          </div>

          <!-- Validation Error List -->
          <div class="errorDiv" v-if="include.targetAudianceStepErrors.length > 0">
            <div :key="index" v-for="(error, index) in include.targetAudianceStepErrors">
              <i class="fa fa-exclamation-triangle"></i> &nbsp;
              {{ error }}
            </div>
          </div>

          <!-- Audience Type Selector -->
          <el-radio-group class="audianceSegmentOption" v-model="include.targetAudiance.audiance_type" size="small">
            <el-radio label="all" border>All Customers</el-radio>
            <!-- <template v-if="!hideOldSegmentOptionsInInclude"> -->
            <el-radio label="segment" border>To Segment </el-radio>
            <el-radio label="custom" border>Build Custom Segment</el-radio>
            <el-radio label="static_list" border>To Static List</el-radio>
            <el-radio label="segment-v2" border>To Segments Or Static Lists (BETA)</el-radio>
            <!-- </template> -->
          </el-radio-group>

          <!-- Existing Segment Dropdown -->
          <div class="mt-6" v-if="include.targetAudiance.audiance_type == 'segment-v2'">
            <div class="text-xs text-gray-500 mb-1">Segments To Include</div>
            <div class="form-dropdown">
              <el-select class="w-full" v-model="include.targetAudiance.segment_v2.segments" filterable placeholder="Select Segment" multiple>
                <el-option v-for="segmentItem in segmentList" :key="segmentItem.id" :label="segmentItem.name + (segmentItem.is_deleted ? ' (Deleted)' : '')" :value="segmentItem.id"> </el-option>
              </el-select>
            </div>

            <!-- Static Lists -->
            <div class="text-sm text-gray-500 mt-6 mb-1">Static Lists To Include</div>
            <div class="form-dropdown">
              <el-select multiple class="w-full" v-model="include.targetAudiance.segment_v2.lists" filterable placeholder="Select Static Lists">
                <el-option v-for="item in staticSegmentList" :key="item.id" :label="item.name + (item.is_deleted ? ' (Deleted)' : '')" :value="item.id"> </el-option>
              </el-select>
            </div>
          </div>

          <!-- Custom Audience Builder -->
          <el-card class="box-card customSegmentCard" shadow="never" v-if="include.targetAudiance.audiance_type == 'custom'">
            <UserPropertyFilterComponent v-bind:filterTitle="'  Custom Segment Filters'" ref="includeUserFilter" v-bind:formattedPropertyFilter="include.targetAudiance.filter"></UserPropertyFilterComponent>
          </el-card>

          <!-- Existing Segment Dropdown -->
          <div class="existingSegmentCard" v-if="include.targetAudiance.audiance_type == 'segment'">
            <div class="form-title">Select Segment</div>
            <div class="form-dropdown">
              <el-select v-model="include.targetAudiance.segment_id" filterable placeholder="Select Segment" size="small">
                <el-option v-for="item in segmentList" :key="item.id" :label="item.name + (item.is_deleted ? ' (Deleted)' : '')" :value="item.id"> </el-option>
              </el-select>
            </div>
          </div>

          <!-- Existing Static List Dropdown -->
          <div class="existingSegmentCard" v-if="include.targetAudiance.audiance_type == 'static_list'">
            <div class="form-title">Select Static List</div>
            <div class="form-dropdown">
              <el-select style="width: 500px" v-model="include.targetAudiance.static_segment_id" filterable placeholder="Select Static List" size="small">
                <el-option v-for="item in staticSegmentList" :key="item.id" :label="item.name + (item.is_deleted ? ' (Deleted)' : '')" :value="item.id"> </el-option>
              </el-select>
            </div>
          </div>
        </el-card>
      </template>

      <!-- Exclude Customers -->
      <template>
        <div class="audianceInfo rounded-md overflow-hidden border">
          <div class="bg-gray-50 p-3">
            <el-checkbox class="text-md" v-model="excludeCustomers">
              <div class="text-primary-600">Exclude Customers</div>
            </el-checkbox>
            <div class="ml-4 text-gray-500 text-xs -mt-1 leading-4">Mention segments that you want to exclude. Customers matched in exclude critera will not receive campaign messages.</div>
          </div>

          <div class="w-full border-t p-4" v-if="excludeCustomers">
            <!-- Validation Error List -->
            <div class="errorDiv" v-if="exclude.targetAudianceStepErrors.length > 0">
              <div :key="index" v-for="(error, index) in exclude.targetAudianceStepErrors">
                <i class="fa fa-exclamation-triangle"></i> &nbsp;
                {{ error }}
              </div>
            </div>

            <!-- Audience Type Selector -->
            <!-- <template v-if="!hideOldSegmentOptionsInExclude"> -->
            <el-radio-group class="mb-6 audianceSegmentOption" v-model="exclude.targetAudiance.audiance_type" size="small">
              <el-radio label="segment" border>Segment </el-radio>
              <el-radio label="custom" border>Custom Segment</el-radio>
              <el-radio label="static_list" border>Static List</el-radio>
              <el-radio label="segment-v2" border>Segments Or Static Lists (BETA)</el-radio>
            </el-radio-group>
            <!-- </template> -->

            <!-- Segment V2 -->
            <div class="mt-3" v-if="exclude.targetAudiance.audiance_type == 'segment-v2'">
              <div class="text-xs text-gray-500 mb-1">Segments To Exclude</div>
              <div class="form-dropdown">
                <el-select class="w-full" v-model="exclude.targetAudiance.segment_v2.segments" filterable placeholder="Select Segment" multiple>
                  <el-option v-for="segmentItem in segmentList" :key="segmentItem.id" :label="segmentItem.name + (segmentItem.is_deleted ? ' (Deleted)' : '')" :value="segmentItem.id"> </el-option>
                </el-select>
              </div>

              <!-- Static Lists -->
              <div class="text-sm text-gray-500 mt-6 mb-1">Static Lists To Exclude</div>
              <div class="form-dropdown">
                <el-select multiple class="w-full" v-model="exclude.targetAudiance.segment_v2.lists" filterable placeholder="Select Static Lists">
                  <el-option v-for="item in staticSegmentList" :key="item.id" :label="item.name + (item.is_deleted ? ' (Deleted)' : '')" :value="item.id"> </el-option>
                </el-select>
              </div>
            </div>

            <!-- Custom Audience Builder -->
            <el-card class="box-card customSegmentCard" shadow="never" v-if="exclude.targetAudiance.audiance_type == 'custom'">
              <UserPropertyFilterComponent v-bind:filterTitle="'  Custom Segment Filters'" ref="excludeUserFilter" v-bind:formattedPropertyFilter="exclude.targetAudiance.filter"></UserPropertyFilterComponent>
            </el-card>

            <!-- Existing Segment Dropdown -->
            <div class="existingSegmentCard" v-if="exclude.targetAudiance.audiance_type == 'segment'">
              <div class="form-title">Select Segment</div>
              <div class="form-dropdown">
                <el-select v-model="exclude.targetAudiance.segment_id" filterable placeholder="Select Segment" size="small">
                  <el-option v-for="item in segmentList" :key="item.id" :label="item.name + (item.is_deleted ? ' (Deleted)' : '')" :value="item.id"> </el-option>
                </el-select>
              </div>
            </div>

            <!-- Existing Static List Dropdown -->
            <div class="existingSegmentCard" v-if="exclude.targetAudiance.audiance_type == 'static_list'">
              <div class="form-title">Select Static List</div>
              <div class="form-dropdown">
                <el-select style="width: 500px" v-model="exclude.targetAudiance.static_segment_id" filterable placeholder="Select Static List" size="small">
                  <el-option v-for="item in staticSegmentList" :key="item.id" :label="item.name + (item.is_deleted ? ' (Deleted)' : '')" :value="item.id"> </el-option>
                </el-select>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- Step Footer -->
      <CampaignFooter :step="step" v-if="campaign" :campaign="campaign" @onNext="onGoNext" @onPrev="onGoBack" @onSaveDraft="onSaveDraft"></CampaignFooter>
    </template>
  </div>
</template>
<style lang="scss" src="./campaignTargetAudiance.scss"></style>

<script>
import campaignTargetAudianceComponent from './campaignTargetAudianceComponent';
export default campaignTargetAudianceComponent;
</script>
